import { createStore } from 'vuex'
import createMutationsSharer from 'vuex-shared-mutations'
import SimpleModuleMaker from './simple-module-maker'
import createCustomPropertiesModule from './modules/custom-properties'
import createCrmStagesModule from './modules/crm-stages'
import createSavedFiltersModule from './modules/saved-filters'
import createTagsModule from './modules/tags'

export default function () {
  const plugins = []

  try {
    const sharer = createMutationsSharer({
      predicate: (mutation, state) => {
        const notSharedMutations = [
          'Video/loading',
          'Video/loaded'
        ]

        return notSharedMutations.indexOf(mutation.type) === -1
      }
    })

    plugins.push(sharer)
  } catch (e) {

  }

  return createStore({
    plugins,
    modules: {
      CrmDealPipelines: SimpleModuleMaker('api.crm-deal-pipeline.index'),
      CustomProperties: createCustomPropertiesModule(),
      CrmStages: createCrmStagesModule(),
      EmailSignature: SimpleModuleMaker('api.email-signature.show'),
      SavedFilters: createSavedFiltersModule(),
      Tags: createTagsModule(),
      TeamMembers: SimpleModuleMaker('api.team.member.index', { no_pagination: '1' }),
      TeamGroups: SimpleModuleMaker('api.team.group.index'),
      TeamRoles: SimpleModuleMaker('api.team.role.index'),
      YoutubeCredentials: SimpleModuleMaker('api.youtube_credential.index')
    }
  })
}

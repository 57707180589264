import { ReadableStream } from 'web-streams-polyfill/es5'
import $ from 'jquery'
import Store from 'store'
import VueLazyload from 'vue-lazyload'
import 'bootstrap'
import 'slick-carousel'
import { router } from './router'
import createStore from './vuex'
import { setupApp } from './common/setup-app/setup-app'
import { bus } from './common/setup-app/event-bus'
import './configuration'

window.$ = window.jQuery = $

if (!window.ReadableStream) {
  window.ReadableStream = ReadableStream
}

const app = setupApp()
if (app) {
  app.use(createStore())
  app.use(VueLazyload)
}

$(document).ready(() => {
  const $body = $('body')
  $('#kt_header_mobile_topbar_toggle').click(() => {
    $body.toggleClass('topbar-mobile-on')
  })
  $('#kt_aside_mobile_toggle').click(() => {
    $('#kt_aside').toggleClass('aside-on')
    $body.toggleClass('aside-on')
  })
  $('.aside-overlay, .aside a').on('click', () => {
    $('#kt_aside').removeClass('aside-on')
    $body.removeClass('aside-on')
  })

  // Aside toggle
  let insideTm
  let outsideTm

  function getMatchedGroupRoute (route) {
    if (route.matched.length > 0) {
      const groupRoutes = route.matched.filter(route => typeof route.meta.group !== 'undefined')

      if (groupRoutes.length > 0) {
        return groupRoutes[0]
      }
    }

    return null
  }

  $('#kt_aside_toggle').click(() => {
    $body.toggleClass('aside-minimize')
    if (router.currentRoute.value) {
      const groupRoute = getMatchedGroupRoute(router.currentRoute.value)

      if (groupRoute !== null) {
        const routeGroup = groupRoute.meta.group
        Store.set(`aside-minimized:${routeGroup}`, $body.hasClass('aside-minimize') ? '1' : '0')
      }
    }
  })
  $('#kt_aside_menu').on('mouseenter', e => {
    e.preventDefault()
    if (outsideTm) {
      clearTimeout(outsideTm)
      outsideTm = null
    }

    if (insideTm) {
      clearTimeout(insideTm)
      insideTm = null
    }

    insideTm = setTimeout(() => {
      if ($body.hasClass('aside-minimize')) {
        $body.addClass('aside-minimize-hover')
      }
    }, 50)
  })
  $('#kt_aside').on('mouseleave', e => {
    e.preventDefault()

    if (insideTm) {
      clearTimeout(insideTm)
      insideTm = null
    }

    if (outsideTm) {
      clearTimeout(outsideTm)
      outsideTm = null
    }

    outsideTm = setTimeout(() => {
      if ($body.hasClass('aside-minimize-hover')) {
        $body.removeClass('aside-minimize-hover')
      }
    }, 100)
  })
  router.afterEach((to, from) => {
    const groupRoute = getMatchedGroupRoute(to)

    if (groupRoute !== null) {
      const routeGroup = groupRoute.meta.group
      const minimized = Store.get(`aside-minimized:${routeGroup}`)

      if (minimized === '1' || (typeof minimized === 'undefined' && groupRoute.meta['aside-minimized'] === true)) {
        $body.addClass('aside-minimize')
      } else {
        $body.removeClass('aside-minimize')
      }
    } else {
      $body.removeClass('aside-minimize')
    }
  })
  // end Aside toggle

  // Quick panels
  $body.on('click', '.offcanvas-overlay, .kt_quick_panel_close', () => {
    $('#quick-panel .offcanvas-on').each((index, el) => {
      const id = $(el).attr('id')

      if (id) {
        bus.$emit('hide-quick-panel', '#' + id)
      }
    })
    $('.offcanvas-overlay').addClass('d-none')
  })

  $body.on('click', '.dropdown-menu.hold-on-click', function (e) {
    e.stopPropagation()
  })
  bus.$on('show-quick-panel', selector => {
    $body.find(selector).addClass('offcanvas-on')
    $('.offcanvas-overlay').removeClass('d-none')
  })
  bus.$on('hide-quick-panel', selector => {
    $body.find(selector).removeClass('offcanvas-on')
    $('.offcanvas-overlay').addClass('d-none')
  })
})

import maps from '@/generated/maps.js'
import axios from 'axios'

export default function () {
  return {
    namespaced: true,
    state: () => {
      const state = {}

      Object.keys(maps.App_Models_Tag.typeMap).forEach(type => {
        state[type] = {
          loaded: false,
          loading: false,
          data: []
        }
      })

      return state
    },
    mutations: {
      loading (state, { type }) {
        state[type] = {
          loaded: false,
          loading: true,
          data: []
        }
      },
      loaded (state, { type, data }) {
        state[type] = {
          loaded: true,
          loading: false,
          data
        }
      },
      append (state, tag) {
        state[tag.type].data = [
          ...state[tag.type].data,
          tag
        ]
      }
    },
    actions: {
      loadData ({ commit, state }, { type, force = false, ...rest }) {
        if ((force || !state[type].loaded) && !state[type].loading) {
          commit('loading', { type })
          axios.get(Spark.router('api.tag.index', { filter: { type, ...rest } }))
            .then(resp => {
              commit('loaded', { type, data: resp.data })
            })
        }
      },
      appendData ({ commit }, data) {
        commit('append', data)
      }
    }
  }
}
